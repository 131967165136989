<template>
  <v-container class="page">
    <v-row
      no-gutters
      class="mt-12 justify-center"
    >
      <!-- Headline -->
      <Headline
        v-if="!$store.getters.orgReady"
        :title="welcome"
      />
    </v-row>
    <v-row
      no-gutters
      class="justify-center"
    >
      <v-card
        class="mt-8"
        max-width="600"
      >
        <v-card-title class="primary-bg">
          {{ $t('base.signin.chooseOrganisation') }}
        </v-card-title>

        <v-card-text class="px-8 py-4">
          <v-container>
            <v-row>
              <v-col class="text-left pb-4">
                {{ $t('base.signin.chooseOrgText') }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  :items="$store.state.base.orgList"
                  v-model="selectedOrgUid"
                  :label="$t('base.organisation')"
                  item-text="name"
                  item-value="uid"
                  :filter="customFilter"
                >
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content
                        v-text="data.item.name"
                        class="selector-item"
                      ></v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <SaveCancelButtonBar
              class="mt-4"
              :showCancel="$store.getters.orgReady"
              :saveText="$t('base.continue')"
              @cancel="$router.back()"
              @save="chooseOrganisation"
            />
          </v-container>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script lang="js">
import Headline from '@/common/Headline.vue';
import SaveCancelButtonBar from '@/common/SaveCancelButtonBar';

export default {
  name: 'OrganisationChoose',
  components: {
    Headline, SaveCancelButtonBar
  },
  data () {
    return {
      selectedOrgUid: this.$store.state.base.org.uid
    };
  },
  computed: {
    welcome () {
      return (
        this.$t('base.signin.welcome') +
                ' ' +
                this.$store.state.base.user.firstname +
                ' ' +
                this.$store.state.base.user.surname
      );
    }
  },
  methods: {
    customFilter (item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    async chooseOrganisation () {
      this.$configHelper.resetModuleList();
      this.$store.commit('resetOrg');
      this.$emit('checkAuth', this.selectedOrgUid);
    }
  }
};
</script>

<style scoped>
.selector-item {
  padding: 4px 8px !important;
}
</style>
