<template>
  <v-container class="page">
    <v-flex
      mx-0
      row
      justify-center
    >
      <v-card
        class="mt-8"
        max-width="600"
      >
        <v-card-title class="px-4 py-2 primary-bg">
          {{ $t('base.signin.userInactive') }}
        </v-card-title>

        <v-card-text class="pa-8">
          <v-container>
            <v-row>
              <v-col class="text-left">
                {{ $t('base.signin.userInactiveText') }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-container>
</template>

<script lang="js">

export default {
  name: 'UserInactive'
};
</script>

<style scoped></style>
