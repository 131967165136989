<template>
  <v-container class="align-center pt-8">
    <v-container class="logo">
      <div class="pl-6 py-8 my-8 headline">
        {{ $t('base.signin.goodBye') }}
      </div>
      <v-btn
        color="secondary"
        @click.native="login"
        large
      >{{ $t('base.signin.signInAgain')}}</v-btn>
    </v-container>
  </v-container>
</template>

<script lang="js">
export default {
  name: 'GoodBye',
  mounted () {
    this.$sessionHelper.signOut();
  },
  methods: {
    login () {
      this.$router.push(this.$sessionHelper.getLoginPath());
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
