<template>
  <v-container class="page text-center pt-8">
    <v-row
      no-gutters
      class="justify-center pt-8"
    >
      <v-img
        :src="orgLogo"
        class="logo"
      />
    </v-row>
    <div class="display-1 mt-8">
      {{ $t('base.error.404head') }}
    </div>
    <div class="headline mt-8">
      {{ $t('base.error.404text') }}
    </div>
  </v-container>
</template>

<script>

export default {
  name: 'Error',
  computed: {
    orgLogo () {
      if (this.$store.state.base.darkTheme === true) return this.$configHelper.getResourcesPath() + 'img/logo_ecc_dark.png';
      return this.$configHelper.getResourcesPath() + 'img/logo_ecc.png';
    }
  }
};
</script>

<style scoped>
.logo {
  max-width: 600px;
}
</style>
