<template>
  <v-container class="maxWidth">
    <Headline
      :title="$t('base.footer.privacy')"
      icon="mdi-format-section"
    />
    <v-card>
      <v-card-text class="text-left">
        <v-row>
          <v-col>
            <h1
              class="mb-4"
              id='datenschutzerklärung'
            >Datenschutzerklärung</h1>
            <p>Wir freuen uns über Ihren Besuch auf unserer Internetseite admin.euroconsultant.cloud und Ihr Interesse an unserem Unternehmen.</p>
            <p>Der Schutz Ihrer personenbezogenen Daten, wie z.B. Geburtsdatum, Name, Telefonnummer, Anschrift etc., ist uns ein wichtiges Anliegen.</p>
            <p>Der Zweck dieser Datenschutzerklärung besteht darin, Sie über die Verarbeitung Ihrer personenbezogenen Daten zu informieren, die wir bei einem Seitenbesuch von Ihnen sammeln. Unsere Datenschutzpraxis steht im Einklang mit den gesetzlichen Regelungen der Datenschutzgrundverordnung der EU (DSGVO). Die nachfolgende Datenschutzerklärung dient der Erfüllung der sich aus der DSGVO ergebenden Informationspflichten. Diese finden sich z.B. in Art. 13 und Art. 14 ff. DSGVO.</p>
            <h2 id='verantwortlicher'>Verantwortlicher</h2>
            <p>Verantwortlicher im Sinne des Art. 4 Nr. 7 DSGVO ist derjenige, der allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.</p>
            <p>Im Hinblick auf unsere Internetseite ist der Verantwortliche:</p>
            <p class="indent">
              Eurobase GmbH<br />
              17, Fausermillen<br />
              6689
              Mertert<br />
              Luxemburg<br /> E-Mail: <a href='mailto:%20contact@eurobase.lu'>contact@eurobase.lu</a><br /> Tel.: +352 749292-1<br /> Fax: +352 740044
            </p>
            <h2 id='kontaktdaten-des-datenschutzbeauftragten'>Kontaktdaten des Datenschutzbeauftragten</h2>
            <p>Wir haben einen Datenschutzbeauftragten gem. Art. 37 DSGVO bestellt. Unseren Datenschutzbeauftragten erreichen Sie unter den nachfolgenden Kontaktdaten:</p>
            <p class="indent">
              DURY Compliance und Consulting GmbH<br />
              Obertorstraße 1<br />
              66111
              Saarbrücken<br />
              Deutschland<br /> E-Mail: <a href='mailto:dsb@datenschutz-compliance.de'>dsb@datenschutz-compliance.de</a>
            </p>
            <h2 id='bereitstellung-der-website-und-erstellung-von-logfiles'>Bereitstellung der Website und Erstellung von Logfiles</h2>
            <p>Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten und Informationen des jeweils abrufenden Gerätes (z.B. Computer, Mobiltelefon, Tablet, etc.).</p>
            <h4 id='welche-personenbezogenen-daten-werden-erhoben-und-in-welchem-umfang-werden-diese-verarbeitet'>Welche personenbezogenen Daten werden erhoben und in welchem Umfang werden diese verarbeitet?</h4>
            <ol type='1'>
              <li>Informationen über den Browsertyp und die verwendete Version;<br /> </li>
              <li>Das Betriebssystem des Abrufgerätes;<br /> </li>
              <li>Hostname des zugreifenden Rechners;<br /> </li>
              <li>Die IP-Adresse des Abrufgerätes;<br /> </li>
              <li>Datum und Uhrzeit des Zugriffs;<br /> </li>
              <li>Websites und Ressourcen (Bilder, Dateien, weitere Seiteninhalte), die auf unserer Internetseite aufgerufen wurden;<br /> </li>
              <li>Websites, von denen das System des Nutzers auf unsere Internetseite gelangte (Referrer-Tracking);<br /> </li>
              <li>Meldung, ob der Abruf erfolgreich war;<br /> </li>
              <li>Übertragene Datenmenge</li>
            </ol>
            <p>Diese Daten werden in den Logfiles unseres Systems gespeichert. Eine Speicherung dieser Daten zusammen mit personenbezogenen Daten eines konkreten Nutzers findet nicht statt, so dass eine Identifizierung einzelner Seitenbesucher nicht erfolgt.</p>
            <h4 id='rechtsgrundlage-für-die-verarbeitung-personenbezogener-daten'>Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h4>
            <p>Art. 6 Abs. 1 lit. f DSGVO (berechtigtes Interesse). Unser berechtigtes Interesse besteht darin, die Erreichung des nachfolgend geschilderten Zwecks zu gewährleisten.</p>
            <h4 id='zweck-der-datenverarbeitung'>Zweck der Datenverarbeitung</h4>
            <p>Die vorübergehende (automatisierte) Speicherung der Daten ist für den Ablauf eines Websitebesuchs erforderlich, um eine Auslieferung der Website zu ermöglichen. Die Speicherung und Verarbeitung der personenbezogenen Daten erfolgt zudem zur Erhaltung der Kompatibilität unserer Internetseite für möglichst alle Besucher und zur Missbrauchsbekämpfung und Störungsbeseitigung. Hierfür ist es notwendig, die technischen Daten des abrufenden Rechners zu loggen, um so frühestmöglich auf Darstellungsfehler, Angriffe auf unsere IT-Systeme und/oder Fehler der Funktionalität unserer Internetseite reagieren zu können. Zudem dienen uns die Daten zur Optimierung der Website und zur generellen Sicherstellung der Sicherheit unserer informationstechnischen Systeme.</p>
            <h4 id='dauer-der-speicherung'>Dauer der Speicherung</h4>
            <p>Die Löschung der vorgenannten technischen Daten erfolgt, sobald sie nicht mehr benötigt werden, um die Kompatibilität der Internetseite für alle Besucher zu gewährleisten, spätestens aber 3 Monate nach Abruf unserer Internetseite.</p>
            <h4 id='widerspruchs--und-löschungsmöglichkeit'>Widerspruchs- und Löschungsmöglichkeit</h4>
            <p>Sie können der Verarbeitung jederzeit gem. Art. 21 DSGVO widersprechen und eine Löschung von Daten gem. Art. 17 DSGVO verlangen. Welche Rechte Ihnen zustehen und wie Sie diese geltend machen, finden Sie im unteren Bereich dieser Datenschutzerklärung.</p>
            <h2 id='besondere-funktionen-der-internetseite'>Besondere Funktionen der Internetseite</h2>
            <p>Unsere Seite bietet Ihnen verschiedene Funktionen, bei deren Nutzung von uns personenbezogene Daten erhoben, verarbeitet und gespeichert werden. Nachfolgend erklären wir, was mit diesen Daten geschieht:</p>
            <h3 id='login-bereich'>Login-Bereich</h3>
            <h4 id='welche-personenbezogenen-daten-werden-erhoben-und-in-welchem-umfang-werden-diese-verarbeitet-1'>Welche personenbezogenen Daten werden erhoben und in welchem Umfang werden diese verarbeitet?</h4>
            <p>Die von Ihnen bei uns eingegebenen Registrierungs- und Login-Daten werden wir zur Erfüllung des unten genannten Zwecks verarbeiten.</p>
            <h4 id='rechtsgrundlage-für-die-verarbeitung-personenbezogener-daten-1'>Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h4>
            <p>Art. 6 Absatz 1 lit. f DSGVO zur Wahrung unserer berechtigten Interessen. Das berechtigte Interesse liegt in der Vertragserfüllung mit Ihrem Arbeitgeber.</p>
            <h4 id='zweck-der-datenverarbeitung-1'>Zweck der Datenverarbeitung</h4>
            <p>Sie haben auf unserer Internetseite die Möglichkeit einen separaten Login-Bereich zu nutzen. Damit wir ihre Berechtigung zur Nutzung des geschützten Bereich prüfen können, müssen Sie ihre Login Daten (E-Mail bzw. Nutzername und Passwort) in das entsprechende Formular eingeben. Falls Sie Ihr Passwort oder Ihren Benutzernamen für diesen Bereich vergessen haben sollten, besteht die Möglichkeit sich diese Daten nach vorheriger Eingabe Ihrer Kontaktdaten (E-Mail-Adresse) erneut zusenden zu lassen. Die im Rahmen der Nutzung des Login-Bereiches anfallenden Nutzungsdaten werden von uns nur zur Missbrauchsbekämpfung und Störungsbeseitigung bzw. zur Aufrechterhaltung der Funktionalität erhoben, gespeichert und verarbeitet. Eine Verwendung zu anderen Zwecken oder eine Datenweitergabe an Dritte erfolgt nicht.</p>
            <h4 id='dauer-der-speicherung-1'>Dauer der Speicherung</h4>
            <p>Die erhobenen Daten werden so lange gespeichert, wie Sie ein Benutzerkonto bei uns unterhalten.</p>
            <h4 id='widerspruchs--und-löschungsmöglichkeit-1'>Widerspruchs- und Löschungsmöglichkeit</h4>
            <p>Welche Rechte Ihnen zustehen, und wie Sie diese geltend machen, finden Sie im unteren Bereich dieser Datenschutzerklärung.</p>
            <h4 id='erforderlichkeit-der-angabe-personenbezogener-daten'>Erforderlichkeit der Angabe personenbezogener Daten</h4>
            <p>Die Nutzung des Login-Bereichs auf unserer Seite ist zur Nutzung des geschützten Bereiches vertraglich vorgeschrieben. Die Nutzung des durch den Login-Bereich geschützten Inhalts ist ohne die Eingabe der personenbezogenen Daten nicht möglich. Die Eingabe der Daten setzt das Bestehen eines Nutzerkontos voraus. Eine Anmeldung ist nicht möglich, wenn die von Ihnen eingegebenen Daten falsch sind. Sofern die Daten von Ihnen falsch oder nicht eingegeben werden, kann der geschützte Bereich nicht genutzt werden. Der Rest der Seite ist jedoch weiterhin ohne Login nutzbar.</p>
            <h3 id='konditionenhistorie-geschützter-bereich'>Konditionenhistorie (geschützter Bereich)</h3>
            <h4 id='welche-personenbezogenen-daten-werden-erhoben-und-in-welchem-umfang-werden-diese-verarbeitet-2'>Welche personenbezogenen Daten werden erhoben und in welchem Umfang werden diese verarbeitet?</h4>
            <p>Der mit Ihrem Benutzerzugang verknüpfte Name, Datum, Uhrzeit und Status der Konditionenänderung werden wir zur Erfüllung des unten genannten Zwecks verarbeiten.</p>
            <h4 id='rechtsgrundlage-für-die-verarbeitung-personenbezogener-daten-2'>Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h4>
            <p>Art. 6 Absatz 1 lit. f DSGVO zur Wahrung unserer berechtigten Interessen. Unser berechtigtes Interesse ist die Nachvollziehbarkeit von Konditionenänderungen durch Anwender und die Sicherstellung der Integrität der Konditionendaten.</p>
            <h4 id='zweck-der-datenverarbeitung-2'>Zweck der Datenverarbeitung</h4>
            <p>Sie haben im geschützten Bereich die Möglichkeit, die Konditionen des Euroconsultant anzupassen. Damit wir Änderungen an den Konditionen nachvollziehen und die Integrität der Änderungen sicherstellen können, speichern wir Änderungen der Konditionen in einer Konditionenhistorie.</p>
            <h4 id='dauer-der-speicherung-2'>Dauer der Speicherung</h4>
            <p>Die erhobenen Daten werden so lange gespeichert, wie Sie ein Benutzerkonto bei uns unterhalten.</p>
            <h4 id='widerspruchs--und-löschungsmöglichkeit-2'>Widerspruchs- und Löschungsmöglichkeit</h4>
            <p>Welche Rechte Ihnen zustehen, und wie Sie diese geltend machen, finden Sie im unteren Bereich dieser Datenschutzerklärung.</p>
            <h2 id='datensicherheit-und-datenschutz-kommunikation-per-e-mail'>Datensicherheit und Datenschutz, Kommunikation per E-Mail</h2>
            <p>Ihre personenbezogenen Daten werden durch technische und organisatorische Maßnahmen so bei der Erhebung, Speicherung und Verarbeitung geschützt, dass sie für Dritte nicht zugänglich sind. Bei einer unverschlüsselten Kommunikation per E-Mail kann die vollständige Datensicherheit auf dem Übertragungsweg zu unseren IT-Systemen von uns nicht gewährleistet werden, sodass wir bei Informationen mit hohem Geheimhaltungsbedürfnis eine verschlüsselte Kommunikation oder den Postweg empfehlen.</p>
            <h2 id='auskunftsanspruch-und-berichtigungswünsche-löschung-einschränkung-von-daten---widerruf-von-einwilligungen-widerspruchsrecht'>Auskunftsanspruch und Berichtigungswünsche – Löschung &amp; Einschränkung von Daten - Widerruf von Einwilligungen – Widerspruchsrecht</h2>
            <h3 id='auskunftsanspruch'>Auskunftsanspruch</h3>
            <p>Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob wir personenbezogene Daten von Ihnen verarbeiten. Sofern dies der Fall ist, haben Sie ein Recht auf Auskunft über die in Art. 15 Abs. 1 DSGVO benannten Informationen, soweit nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden (vgl. Art. 15 Abs. 4 DSGVO). Gerne stellen wir Ihnen auch eine Kopie der Daten zur Verfügung.</p>
            <h3 id='berichtigungsanspruch'>Berichtigungsanspruch</h3>
            <p>Sie haben gem. Art. 16 DSGVO das Recht, bei uns ggf. falsch hinterlegte personenbezogene Daten (wie z.B. Adresse, Name, etc.) jederzeit korrigieren zu lassen. Auch können Sie jederzeit eine Vervollständigung der bei uns gespeicherten Daten verlangen. Eine entsprechende Anpassung erfolgt unverzüglich.</p>
            <h3 id='recht-auf-löschung'>Recht auf Löschung</h3>
            <p>Sie haben gem. Art. 17 Abs. Abs. 1 DSGVO das Recht darauf, dass wir die über Sie erhobenen personenbezogenen Daten löschen, wenn</p>
            <ul>
              <li>die Daten entweder nicht mehr benötigt werden;</li>
              <li>aufgrund des Widerrufs Ihrer Einwilligung die Rechtsgrundlage der Verarbeitung ersatzlos entfallen ist;</li>
              <li>Sie Widerspruch gegen die Verarbeitung eingelegt haben und keine berechtigten Gründe für die Verarbeitung vorliegen;</li>
              <li>Ihre Daten unrechtmäßig verarbeitet werden;</li>
              <li>eine rechtliche Verpflichtung dies erfordert oder eine Erhebung gem. Art. 8 Abs. 1 DSGVO stattgefunden hat.</li>
            </ul>
            <p>Das Recht besteht gem. Art. 17 Abs. 3 DSGVO dann nicht, wenn</p>
            <ul>
              <li>die Verarbeitung zur Ausübung des Rechtes auf freie Meinungsäußerung und Information erforderlich ist;</li>
              <li>Ihre Daten auf Grundlage einer rechtlichen Verpflichtung erhoben worden sind;</li>
              <li>die Verarbeitung aus Gründen des öffentlichen Interesses erforderlich ist;</li>
              <li>die Daten zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich sind.</li>
            </ul>
            <h3 id='recht-auf-einschränkung-der-verarbeitung'>Recht auf Einschränkung der Verarbeitung</h3>
            <p>Gem. Art. 18 Abs. 1 DSGVO haben Sie in einzelnen Fällen das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</p>
            <p>Dies ist dann der Fall, wenn</p>
            <ul>
              <li>die Richtigkeit der personenbezogenen Daten von Ihnen bestritten wird;</li>
              <li>die Verarbeitung unrechtmäßig ist und Sie einer Löschung nicht zustimmen;</li>
              <li>die Daten nicht länger für den Verarbeitungszweck benötigt werden, aber die erhobenen Daten der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dienen;</li>
              <li>ein Widerspruch gegen die Verarbeitung gem. Art. 21 Abs. 1 DSGVO eingelegt worden ist und noch unklar ist, welche Interessen überwiegen.</li>
            </ul>
            <h3 id='recht-auf-widerruf'>Recht auf Widerruf</h3>
            <p>Sofern Sie uns eine ausdrückliche Einwilligung in die Verarbeitung Ihrer personenbezogenen Daten erteilt haben (Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO) können Sie diese jederzeit widerrufen. Bitte beachten Sie, dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung hierdurch nicht berührt wird.</p>
            <h3 id='recht-auf-widerspruch'>Recht auf Widerspruch</h3>
            <p>Sie haben gem. Art. 21 DSGVO das Recht, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 lit. f (im Rahmen eines berechtigten Interesses) erhoben worden sind, Widerspruch einzulegen. Das Recht steht Ihnen nur zu, wenn gegen die Speicherung und Verarbeitung besondere Umstände sprechen.</p>
            <h3 id='wie-nehmen-sie-ihre-rechte-wahr'>Wie nehmen Sie Ihre Rechte wahr?</h3>
            <p>Ihre Rechte können Sie jederzeit wahrnehmen, indem Sie sich an die unten stehenden Kontaktdaten wenden:</p>
            <p class="indent">
              Eurobase GmbH<br />
              17, Fausermillen<br />
              6689
              Mertert<br />
              Luxemburg<br /> E-Mail: <a href='mailto:%20contact@eurobase.lu'>contact@eurobase.lu</a><br /> Tel.: +352 749292-1<br /> Fax: +352 740044
            </p>
            <h2 id='recht-auf-datenübertragbarkeit'>Recht auf Datenübertragbarkeit</h2>
            <p>Sie haben gem. Art. 20 DSGVO einen Anspruch auf Übermittlung der Sie betreffenden personenbezogenen Daten. Die Daten werden von uns in einem strukturierten, gängigen und maschinenlesbaren Format zur Verfügung gestellt. Die Daten können hierbei wahlweise an Sie selbst oder an einen von Ihnen benannten Verantwortlichen übersendet werden.</p>
            <p>Wir stellen Ihnen auf Anfrage gem. Art. 20 Abs. 1 DSGVO folgende Daten bereit:</p>
            <ul>
              <li>Daten, die aufgrund einer ausdrücklichen Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO erhoben wurden;</li>
              <li>Daten, die wir gemäß gem. Art. 6 Abs. 1 lit. b DSGVO im Rahmen bestehender Verträge von Ihnen erhalten haben;</li>
              <li>Daten, die im Rahmen eines automatisierten Verfahrens verarbeitet worden sind.</li>
            </ul>
            <p>Die Übertragung der personenbezogenen Daten direkt an einen von Ihnen gewünschten Verantwortlichen werden wir vornehmen, soweit dies technisch machbar ist. Bitte beachten Sie, dass wir Daten, die in die Freiheiten und Rechte anderer Personen eingreifen gem. Art. 20 Abs. 4 DSGVO nicht übertragen dürfen.</p>
            <h2 id='beschwerderecht-bei-der-aufsichtsbehörde-gem.-art.-77-abs.-1-dsgvo'>Beschwerderecht bei der Aufsichtsbehörde gem. Art. 77 Abs. 1 DSGVO</h2>
            <p>Sofern Sie den Verdacht haben, dass auf unserer Seite Ihre Daten rechtswidrig verarbeitet werden, können Sie selbstverständlich jederzeit eine gerichtliche Klärung der Problematik herbeiführen. Zudem steht Ihnen jede andere rechtliche Möglichkeit offen. Unabhängig davon steht Ihnen gem. Art. 77 Abs. 1 DSGVO die Möglichkeit zur Verfügung, sich an eine Aufsichtsbehörde zu wenden. Das Beschwerderecht gem. Art. 77 DSGVO steht Ihnen in dem EU-Mitgliedstaat Ihres Aufenthaltsortes, Ihres Arbeitsplatzes und/oder des Ortes des vermeintlichen Verstoßes zu, d.h. Sie können die Aufsichtsbehörde, an die Sie sich wenden, aus den oben genannten Orten wählen. Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet Sie dann über den Stand und die Ergebnisse Ihrer Eingabe, einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs gem. Art. 78 DSGVO.</p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <SaveCancelButtonBar
          class="mt-4"
          :cancel-text="$t('base.back')"
          :showSave="false"
          @cancel="$router.back()"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Headline from '@/common/Headline.vue';
import SaveCancelButtonBar from '@/common/SaveCancelButtonBar';

export default {
  name: 'Privacy',
  components: {
    Headline,
    SaveCancelButtonBar
  },
  computed: {
  }
};
</script>

<style scoped>
.maxWidth {
  max-width: 1020px;
}
p.indent {
  margin-left: 16px;
}
</style>
