<template>
  <v-container class="maxWidth">
    <Headline
      :title="$t('base.footer.imprint')"
      icon="mdi-format-section"
    />
    <v-card>
      <v-card-text class="text-left">
        <v-row>
          <v-col>
            <p><b>Seitenbetreiber i.S.d. § 5 des </b><b><b>deutschen </b>TMG</b>
            </p>
            <p>Eurobase GmbH<br />17, Fausermillen<br />6689 Mertert<br />Luxemburg
            </p>
            <p>Telefon: +352 74 92 921<br />Fax: +352 74 00 44
            </p>
            <p>E-Mail: <a
                href="mailto:contact@eurobase.lu"
                class="secondary--text"
              >contact@eurobase.lu</a><br />Internet: <a
                href="http://www.eurobase.lu"
                class="secondary--text"
              >www.eurobase.lu</a>
            </p>
            <p><b>Geschäftsführer:</b><br />Peter Kühnel, Dipl.-Ing.(FH)<br />Edgar Kertels, Dipl.-Ing.(FH)<br />Klaus-Rainer Hank, Dipl.-Wirtsch.-Ing.(FH)
            </p>
            <p><b>Umsatzsteuer-Identifikationsnummer gemäß § 27 a des deutschen Umsatzsteuergesetz:</b><br />LU19565331
            </p>
            <p>Handelsregisternummer: B92135<br />Kapitalbetrag: 250.000 €
            </p>
            <p><b>Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 des deutschen RStV:</b><br />Klaus-Rainer Hank, Dipl.-Wirtsch.-Ing.(FH)<br />(Anschrift wie oben)
            </p>
            <p><b>Informationen zur Online-Streitbeilegung:</b><br />Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit.<br />Diese Plattform finden Sie unter folgendem Link: <a
                href="http://ec.europa.eu/consumers/odr/"
                target="_blank"
                class="secondary--text"
              >http://ec.europa.eu/consumers/odr/</a>.<br />Verbraucher können diese Plattform nutzen, um ihre Streitigkeiten aus Online-Verträgen beizulegen. <br /><br /><b>Hinweis gemäß § 36 VSBG:</b><br />Wir werden nicht an alternativen Streitschlichtungsverfahren im Sinne des § 36 VSBG teilnehmen. Die Nutzung einer alternativen Schlichtungsstelle stellt keine zwingende Voraussetzung für das Anrufen zuständiger ordentlicher Gerichte dar.
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <SaveCancelButtonBar
          class="mt-4"
          :cancel-text="$t('base.back')"
          :showSave="false"
          @cancel="$router.back()"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Headline from '@/common/Headline.vue';
import SaveCancelButtonBar from '@/common/SaveCancelButtonBar';

export default {
  name: 'Imprint',
  components: {
    Headline,
    SaveCancelButtonBar
  },
  computed: {
  }
};
</script>

<style scoped>
.maxWidth {
  max-width: 1020px;
}
</style>
